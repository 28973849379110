import React from "react";
import DevelopmentPageTemplate from "../../templates/development-page";
import { Form } from "react-bootstrap";
import "../../threeplay.scss";

export default class Index extends React.Component {
  render() {
    var overview = (
      <>
        <p>
          Radio Buttons should be used when allowing a user to select only one choice out of a combination of different options.
        </p>
        <p>
          The radio control allows for two discrete states: selected and unselected. However, a radio button can be either disabled or active regardless of the state. Disabling a radio button will gray out the content to prevent it from being selected.
        </p>
        <h5>
          Exceptions
        </h5>
        <p>
          1) If you have to show more than 7 elements, consider adding or replacing the list of radio buttons with a Searchable Dropdown components (to be created).
        </p>
        <p>
          2) If more than one element can be selected at a time, consider using a checkbox instead of a radio button.
        </p>
        <p>
          3) If you are asking a user to choose between "Yes" or "No" as two options, consider turning the prompt into a single checkbox that allows a user to toggle between the two states.
        </p>
      </>
    );

    var codeString = `
    <Form>
      <Form.Check type="radio" label="Not Selected" />
      <Form.Check type="radio" defaultChecked="true" label="Selected" />
      <Form.Check type="radio" label="Disabled" disabled />
      <Form.Check type="radio" defaultChecked="true" label="Disabled and Selected" disabled />
    </Form>
    `;

    var code = (
      <>
        <br />
        <h4>Radio Buttons</h4>
        <Form>
            <Form.Check type="radio" label="Not Selected" />
            <Form.Check type="radio" defaultChecked="true" label="Selected" />
            <Form.Check type="radio" label="Disabled" disabled />
            <Form.Check type="radio" defaultChecked="true" label="Disabled and Selected" disabled />
        </Form>
        <br />
      </>
    );

    var codes = [
      {
        code: code,
        codeLanguage: "html",
        codeString: codeString
      }
    ]

    var accessibilitySection = (
      <>
        <h5>Radio Buttons Must Be Keyboard Navigable</h5>
        <p>Since a radio button is an interactive control, it must be focusable and keyboard accessible. If the role is applied to a non-focusable element, use the tabindex attribute to change this. The expected keyboard shortcut for activating a radio button is the Space key.</p>

        <h5>Make the Label Clickable</h5>
        <p>
          In the above examples, you may have noticed that you can toggle a radio button by clicking on its associated <code>&lt;label&gt;</code> element as well as on the radio button itself. This is a really useful feature of HTML form labels that makes it easier to click the option you want, especially on small-screen devices like smartphones. Beyond accessibility, this is another good reason to properly set up <code>&lt;label&gt;</code> elements on your forms.
        </p>
      </>
    );

    return (
      <DevelopmentPageTemplate
        title="Radios"
        overview={overview}
        codes={codes}
        accessibilitySection={accessibilitySection}
      />
    );
  }
}
